import React from 'react'

const Footer = () => {
  return (
    <footer className="text-gray-700 body-font">
          <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-wrap md:text-left text-center -mb-10 -mx-4">
              <div className="lg:w-1/6 md:w-1/2 w-full px-4">
                <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">ACCESO</h2>
                <nav className="list-none mb-10">
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Inicio</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Second Link</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800 ">Planes</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Nosotros</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Soporte</a>
                  </li>
                </nav>
              </div>
              <div className="lg:w-1/6 md:w-1/2 w-full px-4">
                <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">PLANES</h2>
                <nav className="list-none mb-10">
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Basico</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Business</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Pro</a>
                  </li>
                </nav>
              </div>
              
              <div className="lg:w-1/6 md:w-1/2 w-full px-4">
                <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">NOSOTROS</h2>
                <nav className="list-none mb-10">
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Política de Privacidad</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Términos de Uso</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Personalizados</a>
                  </li>
                </nav>
              </div>
              <div className="lg:w-1/6 md:w-1/2 w-full px-4">
                <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">COTIZAR</h2>
                <nav className="list-none mb-10">
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Consultar Online</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Soporte</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Atención al cliente</a>
                  </li>
                </nav>
              </div>
              <div className="lg:w-1/6 md:w-1/2 w-full px-4">
                <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">ACCESO</h2>
                <nav className="list-none mb-10">
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Inicio</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Second Link</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Planes</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Nosotros</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Soporte</a>
                  </li>
                </nav>
              </div>
              <div className="lg:w-1/6 md:w-1/2 w-full px-4">
                <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">PLANES</h2>
                <nav className="list-none mb-10">
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Basico</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Business</a>
                  </li>
                  <li>
                    <a className="text-gray-600 hover:text-gray-800">Pro</a>
                  </li>
                </nav>
              </div>
              
              
              
              
            </div>
          </div>
          <div className="border-t border-gray-200">
            <div className="container px-5 py-8 flex flex-wrap mx-auto items-center">
              <div className="flex md:flex-no-wrap flex-wrap justify-center md:justify-start">
                <input className="sm:w-64 w-40 bg-gray-100 rounded sm:mr-4 mr-2 border border-gray-400 focus:outline-none focus:border-pink-700 text-base py-2 px-4" placeholder="Correo" type="text" />
                <button className="inline-flex text-white bg-gradient-to-r from-pink-700 via-pink-500 to-orange-400  border-0 py-2 px-6 focus:outline-none hover:bg-pink-700 rounded-full">Enviar</button>
                <p className="text-gray-500 text-sm md:ml-6 md:mt-0 mt-2 sm:text-left text-center">Recive promociones adicionales
                  <br className="lg:block hidden"/>y  muchos descuentos.
                </p>
              </div>
              <span className="inline-flex lg:ml-auto lg:mt-0 mt-6 w-full justify-center md:justify-start md:w-auto">
                <a className="text-gray-500">
                  <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                  </svg>
                </a>
                <a className="ml-3 text-gray-500">
                  <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                  </svg>
                </a>
                <a className="ml-3 text-gray-500">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                  </svg>
                </a>
                <a className="ml-3 text-gray-500">
                  <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" className="w-5 h-5" viewBox="0 0 24 24">
                    <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                    <circle cx="4" cy="4" r="2" stroke="none"></circle>
                  </svg>
                </a>
              </span>
            </div>
          </div>
          <div className="bg-gray-200">
            <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
              <p className="text-gray-500 text-sm text-center sm:text-left">© 2020 EsahMark
                <a href="" className="text-gray-600 ml-1" target="_blank" rel="noopener noreferrer">@MCFigue</a>
              </p>
              <span className="sm:ml-auto sm:mt-0 mt-2 sm:w-auto w-full sm:text-left text-center text-gray-500 text-sm">Sitio web de construcción rapida</span>
            </div>
          </div>
        </footer>
  )
}

export default Footer